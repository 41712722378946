import React, { Component, lazy, Suspense } from "react";
import { IonApp, IonSpinner } from "@ionic/react";

import "./SharedCode/theme/variables.css";
import "./App.css";
import { setupIonicReact } from "@ionic/react";

setupIonicReact({
  mode: "md",
});

// import SharedApp from './SharedCode/SharedApp';
const SharedApp = lazy(() => import("./SharedCode/SharedApp"));

const App: React.FC = () => {
  return (
    <IonApp>
      <Suspense
        fallback={
          <div className="loadingScreen">
            <IonSpinner className="loadingScreenSpinner" />
          </div>
        }
      >
        <SharedApp app={"FAST"} />
      </Suspense>
    </IonApp>
  );
};

export default App;
